
import { defineComponent } from "vue-demi";

export default defineComponent({
  name: 'ModalVideo',
  props: {
    input: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      videos: {
        yoomoney: 'https://www.youtube.com/embed/Xl2EBYy3hW',
        tinkoff: null,
        botpay: null
      }
    }
  }
})
